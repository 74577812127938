*{
  font-family: 'Raleway', sans-serif;

}

body {
  margin: 0;
  height:100%;
  min-height:100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mainContent{
  min-height: calc(100vh - 10px);
}
code {

}


blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

.mainTitle{
  text-align: center;
}

.content{
  background-color: rgb(214, 214, 214);
  display:flex;
  flex-direction: column;
  width:85%;
  margin : 0 auto;
}

.sidebar-card{
  width:90%;
  background:white;
  border-radius:5px;
  padding:5px;
  margin-bottom:20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.sidebar-card-title{
  border-bottom: 1px solid gray;
  text-align:center;  
  height:30px;
}

.actionButton{
  background:rgb(62, 178, 207);
  padding : 5px 10px 5px 10px;
  color:white;
  font-weight: bold;
  border-radius:5px;
  cursor: pointer;
}

.inputField{
  padding:5px 10px; 
  outline:none;
  box-shadow: none;
  font-size:18px;
  width:90%;
}

.actionButton:hover{
  background:rgb(115, 202, 224);
}

.grow:hover{
  transform: scale(1.1);
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  color: white;
  padding: 6px 12px;
}

.tag{
  padding : 5px 10px 5px 10px;
  color:white;
  font-size: 10px;
  border-radius:5px;
  margin-left:10px;
}

.article:hover{
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
  cursor: pointer;
}

/* Top Menu Bar */
.mainBar{
  position: fixed;
  background:white;
  width:100%;
  border-bottom: 1px solid rgb(177, 177, 177);
  height: 55px;
  padding:15px;
  display: flex;
  flex-direction: row;
  z-index: 999;
}

.smallMenu{
  margin-left: auto;
}

#mainLogo{
  height: 80px;
  width: 80px;
  margin-right: 30px;
  position: relative;
  bottom: 25px;
}

.topBarIcon{
  color:black;
  
}

.topBarIcon a{
  color:black;
}

.topBarIcon a:hover{
  color:gray;
}

.topBarIcon:hover{
  color:gray;
  cursor: pointer;
}

.smallIcon{
  font-size: 8px;
  color:white;
  margin-left:5px;
}

.smallIcon:hover{
  color:#00447c;
  transform: scale(1.2);
}

.socialIcon:hover{
  color:#fa2a2a;
  transform:scale(1.3);
}

/* Image Uploader Menu */
#imagePickerOverlay{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
}

#imageUploaderContainer{
  padding:15px;
  background: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin:100px auto;
  height:550px;
  width:35%;
}

#imageMenuCloseButton{
  float:right;
  background:red;
  font-weight: bold;
  color:white; 
  text-align: center;
  width:80px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #888888;
  margin:10px;
  padding:5px;
  cursor: pointer;
}

#imageMenuTopBar{
  border-bottom : 1px solid gray;
  height:60px;
}

#imagePickerContainer{
  display :flex;
  align-content: space-between;
  margin-bottom:30px;
  margin-top:10px;
  justify-content: space-between;
  padding:10px 30px 10px 30px;
}

#imageViewerContainer{
  box-shadow: rgb(216, 216, 216) 0px 0px 10px  inset;
  overflow-y: scroll;
  height:320px;
}


/* COMMENT SECTION */

.comment{
  width:100%;
  border : 1px solid #00447c;
  border-radius:6px;
  margin-top : 30px;
  overflow: auto;
  padding:20px;
}

.commentIcon{
  float:left;
  color:#00447c;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* LOGIN SECTION */
.loginContainer {
  width:42em;
  height:350px;
  background : rgb(247, 247, 247);
  margin : 0 auto;
  border-radius: 5px;
  box-shadow: rgb(216, 216, 216) 0px 0px 2px;
  padding:50px;
  text-align: center;
}

.loginContainer button {
  font-size: 30px;
  border: 1px solid rgb(114, 114, 114);
  margin:40px 20px;
  padding : 10px;
  border-radius:4px;
  background : white;
}

.loginContainer button:hover{
  border:1px solid black;
  cursor:pointer;
  background:aliceblue;
}

.loginContainer input{
  margin:10px;
  height:40px;
  padding:10px;
}

/* FOOTER */

.footerContainer{
  width:100%;
  background:rgb(34, 34, 34);
  padding : 20px 0px;
  color:white;
  text-align: center;
}

.footerSection{
  border-top:1px solid white;
  max-width: 1000px;
  margin:0px auto;
  display:flex;
  justify-content: space-between;
}

.footerSection h2{
  display:inline-block;
  margin-right:100px;
}

.footerSection .links{
  margin-top:25px;
}
.footerSection a {
  text-decoration: none;
  color:white;
  margin :40px;
}
